var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "profile-gift-container" },
    [
      _vm.headerProposals.length > 0
        ? _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c(_vm.headerMode, {
                tag: "component",
                attrs: {
                  title: _vm.headerTitle,
                  proposals: _vm.headerProposals,
                  paginationClass: "pagination-header",
                  cols: 1,
                  sm: 2,
                  md: 2,
                  lg: 2
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-container",
        { staticClass: "pa-0", attrs: { fluid: "" } },
        [_c("CategoryTitle", { attrs: { category: _vm.category } })],
        1
      ),
      _c(
        "v-container",
        { staticClass: "pa-0", attrs: { fluid: "" } },
        [
          _vm.filteredAutoAssignedGifts.length > 0
            ? _c("GiftList", {
                attrs: {
                  giftList: _vm.filteredAutoAssignedGifts,
                  title: _vm.$t("profile.gifts.coupon")
                },
                on: { reload: _vm.reload }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-container",
        { staticClass: "pa-0", attrs: { fluid: "" } },
        [
          _vm.filteredManualAssignedGifts.length > 0
            ? _c("GiftList", {
                attrs: {
                  giftList: _vm.filteredManualAssignedGifts,
                  title: _vm.$t("profile.gifts.advantages")
                },
                on: { reload: _vm.reload }
              })
            : _vm._e()
        ],
        1
      ),
      _vm.footerProposals.length > 0
        ? _c(
            "v-container",
            { staticClass: "pa-0", attrs: { fluid: "" } },
            [
              _c(_vm.footerMode, {
                tag: "component",
                attrs: {
                  title: _vm.footerTitle,
                  proposals: _vm.footerProposals,
                  paginationClass: "pagination-footer",
                  cols: 1,
                  sm: 2,
                  md: 2,
                  lg: 2
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }